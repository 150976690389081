import { ReactElement } from 'react';

import { PlatformID } from '@common/clients/api';
import { useContextData } from '@common/useContextData';

export interface Props {
    include?: PlatformID[] | PlatformID;
    exclude?: PlatformID[] | PlatformID;
    children: ReactElement;
}

export const PlatformToggle = ({ include, exclude, children }: Props) => {
    const contextData = useContextData();
    const platform = contextData?.platform.id;

    const isPlatformIncluded = !include || include === platform || include.includes(platform);
    const isPlatformExcluded =
        (!exclude && !isPlatformIncluded) || exclude?.includes(platform) || exclude === platform;

    return isPlatformIncluded && !isPlatformExcluded ? children : null;
};
